import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/master-user-hp",
        name: "master-user-hp",
        component: () => import("./pages/master/UserHp.vue"),
      },
      {
        path: "/master-user-web",
        name: "master-user-web",
        component: () => import("./pages/master/UserWeb.vue"),
      },
      {
        path: "/master-outlet",
        name: "master-outlet",
        component: () => import("./pages/master/Outlet.vue"),
      },
      {
        path: "/master-outlet-assignments",
        name: "master-outlet-assignments",
        component: () => import("./pages/master/OutletAssignments.vue"),
      },
      {
        path: "/visit",
        name: "visit",
        component: () => import("./pages/master/Visit.vue"),
      },
      {
        path: "/activity-promo-internal",
        name: "activity-promo-internal",
        component: () => import("./pages/master/ActivityPromoInternal.vue"),
      },
      {
        path: "/activity-promo-competitor",
        name: "activity-promo-competitor",
        component: () => import("./pages/master/ActivityPromoCompetitor.vue"),
      },
      {
        path: "/stock-input",
        name: "stock-input",
        component: () => import("./pages/master/StockInput.vue"),
      },
      {
        path: "/stock-proposed-order",
        name: "stock-proposed-order",
        component: () => import("./pages/master/StockProposedOrder.vue"),
      },
      {
        path: "/database-frontliner",
        name: "database-frontliner",
        component: () => import("./pages/master/DatabaseFrontliner.vue"),
      },
      {
        path: "/database-outlet",
        name: "database-outlet",
        component: () => import("./pages/master/DatabaseOutlet.vue"),
      },
      {
        path: "/merchandising",
        name: "merchandising",
        component: () => import("./pages/master/Merchandising.vue"),
      },
      {
        path: "/price",
        name: "price",
        component: () => import("./pages/master/Price.vue"),
      },
      {
        path: "/produk-persentasi-data-konsumen",
        name: "produk-persentasi-data-konsumen",
        component: () =>
          import("./pages/master/ProdukPersentasiDataKonsumen.vue"),
      },
      {
        path: "/produk-persentasi-dokumentasi",
        name: "produk-persentasi-dokumentasi",
        component: () =>
          import("./pages/master/ProdukPersentasiDokumentasi.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("authToken")) {
    next({ name: "login" });
  } else if (to.name === "login" && localStorage.getItem("authToken")) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
